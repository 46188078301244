<template>
  <!-- 订单模块 -->
  <div class="orderManage bgblue">
    <!-- 订单展示模块 -->
    <div class="orderManage-top">
      <el-row :gutter="10">
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/order_1.png"
                width="30"
                alt=""
                class="vm"
              />
              <span>新增订单</span>
            </div>
            <div class="fw600 mb10">0</div>
            <div>较昨日<i class="color">&nbsp;&nbsp;0</i></div>
          </div></el-col
        >
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/order_2.png"
                width="30"
                alt=""
                class="vm"
              />
              <span>待分配</span>
            </div>
            <div class="fw600 mb10">0</div>
            <div>较昨日<i class="color">&nbsp;&nbsp;0</i></div>
          </div></el-col
        >
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/order_3.png"
                width="30"
                alt=""
                class="vm"
              />
              <span>订单总数</span>
            </div>
            <div class="fw600 mb10">0</div>
            <div>较昨日<i class="color">&nbsp;&nbsp;0</i></div>
          </div></el-col
        >
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/order_4.png"
                width="30"
                alt=""
                class="vm"
              />
              <span>新客户</span>
            </div>
            <div class="fw600 mb10">0</div>
            <div>较昨日<i class="color">&nbsp;&nbsp;0</i></div>
          </div></el-col
        >
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/order_5.png"
                width="30"
                alt=""
                class="vm"
              />
              <span>成交客户</span>
            </div>
            <div class="fw600 mb10">0</div>
            <div>较昨日<i class="color">&nbsp;&nbsp;0</i></div>
          </div></el-col
        >
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/order_6.png"
                width="30"
                alt=""
                class="vm"
              />
              <span>客户总数</span>
            </div>
            <div class="fw600 mb10">0</div>
            <div>较昨日<i class="color">&nbsp;&nbsp;0</i></div>
          </div></el-col
        >
      </el-row>
    </div>
    <!-- 订单输入框模块 -->
    <div class="orderManage-main">
      <el-form
        :model="formInline"
        class="demo-form-inline"
        style="position: relative"
        label-width="100px"
      >
        <el-row>
          <el-col :span="4"
            ><el-form-item label="订单编号:">
              <el-input
                v-model="formInline.user"
                placeholder="请输入订单编号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="联单号:">
              <el-input
                v-model="formInline.user"
                placeholder="请输入联单号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4">
            <el-form-item label="订单类型:">
              <el-select
                v-model="orderType.region"
                placeholder="请选择订单类型"
                style="width: 100%"
              >
                <el-option
                  v-for="item in orderType"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="订单状态:">
              <el-select
                v-model="orderStatus.region"
                placeholder="请选择订单状态"
                style="width: 100%"
              >
                <el-option
                  v-for="item in orderStatus"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="客户名称:">
              <el-input
                v-model="formInline.user"
                placeholder="请输入客户名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="下单日期:">
              <el-date-picker
                style="width: 100%"
                v-model="value2"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="订单签收日期:">
              <el-date-picker
                v-model="value1"
                style="width: 100%"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-button
              type="primary"
              icon="el-icon-search"
              style="margin-left: 10px; margin-top: 4px"
              >查询</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 订单表格模块 -->
    <div class="orderManage-footer">
      <!-- <div class="textr"> <el-button type="primary" @click="dialogVisible = true"
        >下单</el-button
      ></div> -->
      <el-dialog
        title="新建订单"
        :visible.sync="dialogVisible"
        width="70%"
        height="60%"
      >
        <div class="addOrder">
          <div class="userInfomation">
            <span class="span"></span>
            <p class="title">客户信息</p>
            <el-select v-model="value" placeholder="请选择客户">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-table :data="tabData" border height="250px">
              <el-table-column prop="num" label="勘探编号" align="center">
              </el-table-column>
              <el-table-column prop="people" label="勘探人员" align="center">
              </el-table-column>
              <el-table-column prop="username" label="客户名称" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="手机号" align="center">
              </el-table-column>
              <el-table-column prop="address" label="收货地址" align="center">
              </el-table-column>
            </el-table>
          </div>
          <div class="shopInfomation">
            <span class="span"></span>
            <p class="title">物料信息</p>
            <el-select v-model="value4" placeholder="请选择物料">
              <el-option
                v-for="item in tab"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-table :data="userData" border height="250px">
              <el-table-column
                type="index"
                label="序号"
                align="center"
              ></el-table-column>
              <el-table-column prop="uname" label="物料名称" align="center">
              </el-table-column>
              <el-table-column prop="size" label="规格型号" align="center">
              </el-table-column>
              <el-table-column prop="username" label="品牌" align="center">
              </el-table-column>
              <el-table-column prop="num" label="数量/台数" align="center">
                <el-input></el-input>
              </el-table-column>
              <el-table-column prop="address" label="单位" align="center">
              </el-table-column>
              <el-table-column prop="price" label="物料单价" align="center">
              </el-table-column>
              <el-table-column prop="time" label="计划到货时间" align="center">
                <el-input></el-input>
              </el-table-column>
              <el-table-column prop="any" label="备注" align="center">
                <el-input></el-input>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-row type="flex" justify="center">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </el-row>
      </el-dialog>
      <el-table :data="tableData" height="calc(100vh - 390px)" border>
        <el-table-column
          type="index"
          width="100px"
          label="下单日期"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="company"
          label="订单号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="name" label="联单号" width="150" align="center">
        </el-table-column>
        <el-table-column
          prop="exploeNum"
          label="运输方式"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="acheiveNum"
          label="订单类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="agentName"
          label="订单签收日期"
          width="250"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="area"
          label="订单状态"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="客户名称"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="idNumber"
          label="证件号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installionState"
          label="安装状态"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="cooperationType"
          label="合作类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="reviewResults"
          label="复查结果"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="review"
          label="复查人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationRejection"
          label="安装驳回次数"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationRejection"
          label="勘探驳回次数"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="explorationData"
          label="勘探数据类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationDatatype"
          label="安装单据类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="colorSteel"
          label="是否封装彩刚"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationState"
          label="勘探状态"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="powerStation"
          label="电站类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationAnomalies"
          label="勘探异常情况"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="installationException"
          label="安装异常类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationSinglechip "
          label="勘探单片功率(W)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="componentSpecifications"
          label="组件规格"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationTypety"
          label="安装方式"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="roofTypess"
          label="屋顶类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="gridconnectionType"
          label="并网方式"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="numberPlanblocks"
          label="方案块数(块)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationPower"
          label="勘探功率"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationAddress"
          label="勘探地址"
          width="350"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="baseAddress"
          label="基准地址"
          width="350"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="explorationRemarks"
          label="勘探备注"
          width="450"
          align="left"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="explorationCreator"
          label="勘探创建人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationCreationtime"
          label="勘探创建时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationSubmitter"
          label="勘探提交人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationReviewtime"
          label="勘探提交时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installingSinglechippower"
          label="勘探审核人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="numberofInstallationblocks"
          label="勘探审核时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installionPower"
          label="安装单片功率(W)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="inverterColumnnumber"
          label="安装块数(块)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="numberofInverters"
          label="安装功率(W)"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationNumer"
          label="逆变器列序号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="numberofInverters"
          label="逆变器台数"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationAddresss"
          label="安装地址"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationNotes"
          label="安装备注"
          width="150"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="installationCreator"
          label="安装创建人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationCreationtime"
          label="安装创建时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationSubmitter"
          label="安装提交人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationSubmissiontime"
          label="安装提交时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installedBy"
          label="安装更新人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationUpdatetime"
          label="安装更新时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationPreliminaryreviewer"
          label="安装初审人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationInitialreviewtime "
          label="安装初审时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationReviewperson"
          label="安装复审人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationReviewtime"
          label="安装复审时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="financialTrackingnumber"
          label="金融单号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="powerStationPSID"
          label="电站PSID"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="explorationReviewsubmissiontime"
          label="勘探复查提交时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installationReviewsubmissiontime"
          label="安装复查提交时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop=" " label="操作" width="140" align="center" fixed>
          <el-button type="text" class="btn-blue" @click="show = true"
            >查看</el-button
          >
          <el-button type="text" class="btn-orange" @click="comfirmshow = true"
            >收货确认</el-button
          >
          <!-- <el-button type="text" class="btn-green" @click="disshow = true"
            >签收信息</el-button
          > -->
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
        >
        </el-pagination>
      </div>
      <el-dialog title="订单详情" :visible.sync="show" width="65%" height="60%">
        <div class="orderNews">
          <div class="newsInfomation">
            <span class="span"></span>
            <p class="title">订单信息</p>
            <el-row type="flex" justify="space-around">
              <el-col :span="6"><div>非标产品</div></el-col>
              <el-col :span="6"><div>订单号:2024000000000000</div></el-col>
              <el-col :span="6"><div>订单下单日期: 2024-02-29</div></el-col>
            </el-row>
            <div>
              <el-steps
                :active="5"
                finish-status="success"
                style="margin: 40px auto"
              >
                <el-step title="发起"></el-step>
                <el-step title="待支付"></el-step>
                <el-step title="已提交"></el-step>
                <el-step title="已接单"></el-step>
                <el-step title="已发货"></el-step>
                <el-step title="已签收"></el-step>
              </el-steps>
            </div>
            <el-table :data="tabData" border>
              <el-table-column prop="num" label="产品名称" align="center">
              </el-table-column>
              <el-table-column prop="people" label="参数" align="center">
              </el-table-column>
              <el-table-column prop="username" label="单价套" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="套数" align="center">
              </el-table-column>
              <el-table-column prop="address" label="金额" align="center">
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <el-button
                  style="
                    color: #03a7f0;
                    border: 1px solid #03a7f0;
                    text-align: center;
                  "
                  @click="reviews = true"
                  >查看物料详情</el-button
                >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-row type="flex" justify="center">
          <el-button type="primary" @click="newsInfo = true"
            >查看结算信息</el-button
          >
        </el-row>
      </el-dialog>
      <el-dialog title="确认签收" :visible.sync="comfirmshow" width="52%">
        <div class="confirmationBox">
          <el-form ref="confirmform" :model="confirmform" label-width="80px">
            <el-form-item label="签收人:">
              <el-input
                v-model="confirmform.name"
                style="width: 50%"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式:">
              <el-input
                v-model="confirmform.phone"
                style="width: 50%"
              ></el-input>
            </el-form-item>
            <el-form-item label="签收时间:">
              <el-input
                v-model="confirmform.time"
                style="width: 50%"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input type="textarea" v-model="confirmform.desc"></el-input>
            </el-form-item>
            <el-form-item label="签收图片:">
              <el-upload
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <el-row type="flex" justify="center">
          <el-button @click="comfirmshow = false">取 消</el-button>
          <el-button type="primary" @click="comfirmshow = false"
            >签收</el-button
          >
        </el-row>
      </el-dialog>
      <el-dialog title="签收信息" :visible.sync="disshow" width="52%">
        <div class="receiptConfirmation">
          <div class="confirmationInfomation">
            <span class="span"></span>
            <p class="title">订单详情</p>
            <el-row
              type="flex"
              justify="space-around"
              style="line-height: 45px"
            >
              <el-col :span="6"><div>订单号:20240000000000</div></el-col>
              <el-col :span="6"><div>订单类型:--</div></el-col>
              <el-col :span="6"
                ><div>
                  订单状态: <span style="color: #a9cb75">已签收</span>
                </div></el-col
              >
            </el-row>
            <el-row type="flex" justify="space-around">
              <el-col :span="6"><div>收货日期: 2024-03-01</div></el-col>
              <el-col :span="6"><div>运输类型:自提</div></el-col>
              <el-col :span="6"><div></div></el-col>
            </el-row>

            <el-table :data="newData" border style="width: 100%" height="300px">
              <el-table-column prop="num" label="产品" align="center">
              </el-table-column>
              <el-table-column prop="people" label="物料分类" align="center">
              </el-table-column>
              <el-table-column prop="username" label="订单数量" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="实际收货数量" align="center">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-row type="flex" justify="center">
          <el-button @click="news = false">取 消</el-button>
          <el-button type="primary" @click="news = false">保 存</el-button>
        </el-row>
      </el-dialog>
      <el-dialog title="订单详情" :visible.sync="reviews" width="50%">
        <div class="reviewConfirmation">
          <div class="reviewInfomation">
            <span class="span"></span>
            <p class="title">物料详情</p>

            <el-table :data="newData" border style="width: 100%" height="300px">
              <el-table-column prop="num" label="产品" align="center">
              </el-table-column>
              <el-table-column prop="people" label="物料分类" align="center">
              </el-table-column>
              <el-table-column prop="username" label="订单数量" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="实际收货数量" align="center">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-row type="flex" justify="center">
          <el-button @click="reviews = false">取 消</el-button>
          <el-button type="primary" @click="reviews = false">保 存</el-button>
        </el-row>
      </el-dialog>
      <el-dialog title="订单详情" :visible.sync="newsInfo" width="60%">
        <div class="reviewConfirmation">
          <!-- 产品 -->
          <div class="reviewInfomation">
            <span class="span"></span>
            <p class="title">产品</p>
            <el-row
              style="background-color: #f2f2f2; font-size: 12px"
              class="textc"
            >
              <el-col :span="16" class="border pt10 pb10"
                >惠农宝（新支架无底座版02）_三相_550W
                (30片)_210单晶_平屋顶_压块_双排_72版型_417935</el-col
              >
              <el-col :span="3" class="border pt10 pb10">数量：1</el-col>
              <el-col :span="5" class="border pt10 pb10">价格：61050.00</el-col>
            </el-row>
          </div>
          <!-- 结算信息 -->
          <div class="reviewInfomation">
            <span class="span"></span>
            <p class="title">结算信息</p>

            <el-table :data="newData" border style="width: 100%">
              <el-table-column prop="num" label="项目" align="center">
              </el-table-column>
              <el-table-column prop="people" label="金额" align="center">
              </el-table-column>
            </el-table>
          </div>
          <!-- 收货信息 -->
          <div class="reviewInfomation">
            <span class="span"></span>
            <p class="title">收获信息</p>

            <div class="test-right">
              <p>收货人:唐生</p>
              <p>联系方式:唐生</p>
              <p>需求发货日期:2024-02-22</p>
              <p>运输方式： 自提</p>
            </div>
            <div class="test-left">
              <p>
                地 址： 安徽省亳州市市辖区华佗大道与茴香路交叉口
                浪潮（亳州）大数据产业园 S01科技楼 3楼302室
              </p>
            </div>
          </div>
        </div>
        <el-row type="flex" justify="center">
          <el-button type="primary" @click="newsInfo = false">返回</el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabData: [
        {
          num: "202403252020330005",
          name: "唐生",
          address: "11",
          username: "23",
          phone: "",
        },
      ],
      newData: [
        {
          num: "202403252020330005",
          name: "唐生",
          address: "111",
          username: "222",
          phone: "",
        },
      ],
      confirmform: {
        name: "赵南龙",
        phone: '17333028520',
        time:'2024-05-15',
        desc: "帮我仔细核对下",
      },
      userData: [
        {
          uname: "2016-05-02",
          size: "王小虎",
          username: "上海市普陀区金沙江路 1518 弄",

          num: "",
          address: "2016-05-02",
          size: "王小虎",
          price: "上海市普陀区金沙江路 1518 弄",
          time: "",
          any: "",
        },
        {
          uname: "2016-05-02",
          size: "王小虎",
          username: "上海市普陀区金沙江路 1518 弄",

          num: "",
          address: "2016-05-02",
          size: "王小虎",
          price: "上海市普陀区金沙江路 1518 弄",
          time: "",
          any: "",
        },
        {
          uname: "2016-05-02",
          size: "王小虎",
          username: "上海市普陀区金沙江路 1518 弄",

          num: "",
          address: "2016-05-02",
          size: "王小虎",
          price: "上海市普陀区金沙江路 1518 弄",
          time: "",
          any: "",
        },
      ],
      orderStatus: [
        {
          region: "选项1",
          label: "黄金糕",
        },
        {
          region: "选项2",
          label: "双皮奶",
        },
        {
          region: "选项3",
          label: "蚵仔煎",
        },
        {
          region: "选项4",
          label: "龙须面",
        },
        {
          region: "选项5",
          label: "北京烤鸭",
        },
      ],
      orderType: [
        {
          region: "选项1",
          label: "黄金糕",
        },
        {
          region: "选项2",
          label: "双皮奶",
        },
        {
          region: "选项3",
          label: "蚵仔煎",
        },
        {
          region: "选项4",
          label: "龙须面",
        },
        {
          region: "选项5",
          label: "北京烤鸭",
        },
      ],
      formInline: {
        user: "",
        region: "",
      },
      tableData: [
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "2023-02-28 16:07:25",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "2023-02-28 16:07:25",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "2023-02-28 16:07:25",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "2023-02-28 16:07:25",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "2023-02-28 16:07:25",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "2023-02-28 16:07:25",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "2023-02-28 16:07:25",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "2023-02-28 16:07:25",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      tab: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      newsInfo: false,
      value1: "",
      value: "",
      value2: "",
      value4: "",
      currentPage4: 1,
      dialogVisible: false,
      show: false,
      disshow: false,
      comfirmshow: false,
      reviews: false,
      news: false,
      newsInfo: false,
    };
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    onSubmit() {
      console.log("submit!");
    },
    handleCurrentChange() {},
    handleSizeChange() {},
  },
};
</script>

<style lang="less">
.orderManage {
  .el-form .el-row .el-col-4 {
    width: 19.5%;
  }
  .el-dialog .el-table .cell {
    padding: 5px;
  }
  .el-date-editor .el-input__icon {
    line-height: 21px !important;
  }

  padding: 10px;
  .orderManage-top {
    .el-form .el-row .el-col-4 {
      width: 19.5%;
    }
    .order-pic {
      background-color: #fff;
      text-align: center;
      box-sizing: border-box;
      padding: 10px;
      box-sizing: border-box;
      .color {
        color: red;
        font-style: normal;
      }
      .pic-top {
        span {
          font-weight: bold;
          font-size: 15px;
        }
      }
      .el-icon-user {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        line-height: 30px;
        background-color: rgb(239, 246, 254);
        text-align: center;
        margin-right: 5px;
        color: #4c92f2;
        font-size: 18px;
      }
    }
  }
  .orderManage-main {
    background-color: #fff;
    margin-top: 10px;
    padding: 10px 0 0 0;
  }
  .orderManage-footer {
    background-color: #fff;
    margin-top: 10px;
    padding: 10px;
    position: relative;
    .el-dialog__title {
      font-weight: 800 !important;
      font-size: 16px !important;
    }
    .orderNews {
      padding: 20px;

      box-sizing: border-box;
      border-top: 1px solid #ccc;
      margin-top: -18px;
      .newsInfomation {
        position: relative;
        .span {
          width: 6px;
          height: 22px;
          background-color: #02a7f0;
          position: absolute;
          top: 0px;
          left: -10px;
        }
      }
      .title {
        font-size: 15px;
        font-weight: 800;
        color: #000;
      }
      .el-steps.el-steps--horizontal {
        width: 60%;
      }
    }
    .receiptConfirmation {
      padding: 20px;
      padding-top: 10px;
      box-sizing: border-box;
      border-top: 1px solid #ccc;
      margin-top: -18px;

      .confirmationInfomation {
        position: relative;

        .span {
          width: 6px;
          height: 22px;
          background-color: #02a7f0;
          position: absolute;
          top: 0px;
          left: -10px;
        }
      }
      .title {
        font-size: 15px;
        font-weight: 800;
      }
      .el-steps.el-steps--horizontal {
        width: 60%;
      }
    }
    .reviewConfirmation {
      padding: 20px;
      padding-top: 10px;
      box-sizing: border-box;
      border-top: 1px solid #ccc;
      margin-top: -18px;
      .reviewInfomation {
        position: relative;

        .span {
          width: 6px;
          height: 22px;
          background-color: #02a7f0;
          position: absolute;
          top: 0px;
          left: -10px;
        }
      }
      .title {
        font-size: 15px;
        font-weight: 800;
      }
    }
    .addOrder {
      padding: 20px;

      box-sizing: border-box;
      border-top: 1px solid #ccc;
      margin-top: -18px;
      .title {
        font-size: 16px;
        font-weight: 800;
      }
      .userInfomation {
        position: relative;
        .span {
          width: 6px;
          height: 22px;
          background-color: #02a7f0;
          position: absolute;
          top: 0px;
          left: -10px;
        }
      }
      .shopInfomation {
        position: relative;
        .span {
          width: 6px;
          height: 22px;
          background-color: #02a7f0;
          position: absolute;
          top: 0px;
          left: -10px;
        }
      }
    }
  }
  .fl {
    float: left;
  }
  .border {
    border: 1px solid #ccc;
  }
  .pagination {
    width: 100%;
    text-align: end;
    background-color: #fff;
  }
}
</style>